import firebase from "./FirebaseConfig";

const firestore = firebase.firestore();

const createDocument = (collection, data) => {
  return firestore.collection(collection).add(data);
};

const FirebaseFirestoreService = {
  createDocument,
};

export default FirebaseFirestoreService;
