import React from "react";

function Footer() {
  return (
    <footer class="w3-center w3-black w3-padding-64">
      <a href="#home" class="w3-button w3-light-grey">
        <i class="fa fa-arrow-up w3-margin-right"></i>To the top
      </a>
      <div class="w3-xlarge w3-section">
        <i class="fa fa-facebook-official w3-hover-opacity me-1"></i>
        <i class="fa fa-instagram w3-hover-opacity me-1"></i>
        <i class="fa fa-snapchat w3-hover-opacity me-1"></i>
        <i class="fa fa-pinterest-p w3-hover-opacity me-1"></i>
        <i class="fa fa-twitter w3-hover-opacity me-1"></i>
        <i class="fa fa-linkedin w3-hover-opacity"></i>
      </div>
      <p>all rights reserved by InsightWearAi © {new Date().getFullYear()}</p>
    </footer>
  );
}

export default Footer;
