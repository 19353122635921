/* eslint-disable no-unused-vars */
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import LandigPage from "./screens/LandigPage";
import Header from "./components/navigation/Header";
import Footer from "./components/navigation/Footer";
import firebase from "./FirebaseConfig";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <main className="minPageHight">
          <Routes>
            <Route path="/" element={<LandigPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
