import React from "react";

function About() {
  return (
    <section>
      <div
        className="w3-container"
        style={{ padding: "128px 16px" }}
        id="about"
      >
        <h3 className="w3-center">ABOUT InsightWearAi</h3>
        <p className="w3-center w3-large">Key features of our company</p>
        <div className="w3-row-padding w3-center" style={{ marginTop: "64px" }}>
          <div className="w3-quarter">
            <i className="fa fa-desktop w3-margin-bottom w3-jumbo w3-center"></i>
            <p className="w3-large">Democratizing techonologies!</p>
            <p>
              InsightWearAI is dedicated to developing innovative and
              cutting-edge technology that empowers blind and visually impaired
              individuals to lead more independent and fulfilling lives.
            </p>
          </div>
          <div className="w3-quarter">
            <i className="fa fa-heart w3-margin-bottom w3-jumbo"></i>
            <p className="w3-large">Vision is for Everyone!</p>
            <p>
              Nearly 3 percent of children younger than 18 are blind or visually
              impaired! We hope to make it slightly easier for them as cheap as
              possible
            </p>
          </div>
          <div className="w3-quarter">
            <i className="fa fa-diamond w3-margin-bottom w3-jumbo"></i>
            <p className="w3-large">OCR</p>
            <p>Help you read books, captions and much more!</p>
          </div>
          <div className="w3-quarter">
            <i className="fa fa-cog w3-margin-bottom w3-jumbo"></i>
            <p className="w3-large">Bringing Efficiency into the equation!</p>
            <p>
              We aim to make it faster to read and navigate towards objects! Do
              more in less time.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
