import React from "react";

function Promo() {
  return (
    <section>
      <div
        className="w3-container w3-light-grey"
        style={{ padding: "128px 16px" }}
      >
        <div className="w3-row-padding">
          <div className="w3-col m6">
            <h3>We know design.</h3>
            <p>
              Introducing a revolutionary device that helps visually impaired
              individuals to navigate the world with ease and independence. This
              device is designed to reduce the cost of accessibility and enhance
              the quality of life for those with visual impairments. With its
              advanced features, it can detect obstacles, read text, and
              identify objects in real-time. It is portable, lightweight, and
              easy to use, making it the perfect companion for visually impaired
              individuals who are always on the go.
            </p>
            <p>
              <a href="#work" className="w3-button w3-black">
                <i className="fa fa-th"></i> View Our Works
              </a>
            </p>
          </div>
          <div className="w3-col m6">
            <img
              className="img-fluid w3-round-large"
              src="/demo-1.png"
              alt="girl using insightWearAi device to assist her in her daily activities"
              width="400"
              height="200"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Promo;
