import React from "react";
import { Container, Navbar, Nav, Stack } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function Header() {
  return (
    <Navbar
      data-testid="header"
      collapseOnSelect
      expand="md"
      className="py-3 px-3 navbar-dark bg-dark fixed-top"
      style={{ width: "100vw" }}
    >
      <Container>
        <LinkContainer
          data-testid="header-home-link"
          to="/"
          className="header-nav-item"
        >
          <Stack direction="horizontal" role={"button"}>
            {/* <LogoSvg className="me-2" /> */}
            <img
              src="/logo.png"
              width="50"
              height="50"
              className="d-inline-block align-top p-0 color-white"
              alt="React Bootstrap logo"
            />
            <Navbar.Brand className="text-white">InsightWearAi</Navbar.Brand>
          </Stack>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-md-auto d-flex flex-row justify-content-evenly mt-3 mt-md-0">
            <LinkContainer
              data-testid="header-about-link"
              to="/about"
              className="header-nav-item"
            >
              <Nav.Link className="text-white d-flex flex-column align-items-center">
                <i className="fas fa-store me-1"></i> <span>About</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              data-testid="header-team-link"
              to="/team"
              className="header-nav-item"
            >
              <Nav.Link className="text-white d-flex flex-column align-items-center">
                <i className="fas fa-calendar-days me-1"></i> <span>Team</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              data-testid="header-work-link"
              to="/work"
              className="header-nav-item"
            >
              <Nav.Link className="text-white d-flex flex-column align-items-center">
                <i className="fas fa-square-rss me-1"></i> <span>Work</span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              data-testid="header-partners-link"
              to="/partners"
              className="header-nav-item"
            >
              <Nav.Link className="text-white d-flex flex-column align-items-center">
                <i className="fas fa-newspaper me-1"></i> <span>Partners</span>
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
