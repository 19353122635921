import React from "react";

function Awards() {
  return (
    <div className="container my-3">
      <h2 className="text-center">Partners & Awards</h2>
      <div className="row  text-center my-5">
        <div className="col-sm-4">
          <i
            className="fa-solid fa-award fa-10x "
            style={{ color: "#0f326c" }}
          ></i>
          <p className="text-center">
            {" "}
            <h3>FERIQUE - 1st Place Award</h3>{" "}
          </p>
        </div>
        <div className="col-sm-4">
          <i
            className="fa-solid fa-award fa-10x "
            style={{ color: "#0f326c" }}
          ></i>
          <p className="text-center">
            {" "}
            <h3>FERIQUE - People's Choice Award</h3>{" "}
          </p>
        </div>
        <div className="col-sm-4 text-center">
          <i
            className="fa-solid fa-award fa-10x "
            style={{ color: "#0f326c" }}
          ></i>
          <p className="text-center">
            <h3>FERIQUE - Judges Award</h3>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Awards;
