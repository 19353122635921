import React from "react";
import { Col } from "react-bootstrap";
import FirebaseFirestoreService from "../../FirebaseFirestoreService";

function Contact() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");

  async function handleSubmit(e) {
    try {
      console.log("submit");
      console.log(e);
      e.preventDefault();
      console.log({
        name,
        email,
        subject,
        message,
        date: new Date().toLocaleString(),
      });
      const response = await FirebaseFirestoreService.createDocument(
        "contacts",
        {
          name,
          email,
          subject,
          message,
          date: new Date().toLocaleString(),
        }
      );

      alert(
        `Successfully sent message. \n We will get back to you as soon as possible \n Thank you!`
      );
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <section>
      <div
        className="w3-container w3-light"
        style={{ padding: "128px 16px" }}
        id="contact"
      >
        <h3 className="w3-center">CONTACT</h3>
        <p className="w3-center w3-large">
          Lets get in touch. Send us a message:
        </p>
        <div
          className="row justify-content-md-center"
          style={{ marginTop: "48px" }}
        >
          <Col xs={12} md={6}>
            <form
              onSubmit={handleSubmit}
              className="g-3 m-2 p-3 border rounded w3-light-grey"
            >
              <p>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  required
                  name="Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </p>
              <p>
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  required
                  name="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </p>
              <p>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Subject"
                  required
                  name="Subject"
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                />
              </p>
              <p>
                <textarea
                  className="form-control"
                  type="textarea"
                  placeholder="Message"
                  required
                  name="Message"
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  style={{ height: "100px" }}
                />
              </p>
              <div className="d-grid gap-2 col-6 mx-auto">
                <button className="btn btn-dark" type="submit">
                  <i className="fa fa-paper-plane"></i> SEND MESSAGE
                </button>
              </div>
            </form>
          </Col>

          <div className="row mt-2">
            <div className="col-md-4 ">
              <i className="fa fa-map-marker fa-fw w3-xxlarge w3-margin-right"></i>{" "}
              Montreal, Canada
            </div>
            <div className="col-md-4">
              <i className="fa fa-phone fa-fw w3-xxlarge w3-margin-right"></i>{" "}
              Phone: +1 514 839 8026
            </div>
            <div className="col-md-4">
              <i className="fa fa-envelope fa-fw w3-xxlarge w3-margin-right">
                {" "}
              </i>{" "}
              Email:{" "}
              <a href="mailto:contact@insightwearai.com">
                contact@insightwearai.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
