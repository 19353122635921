import React from "react";
import About from "../components/landing/About";
import Awards from "../components/landing/Awards";
import Contact from "../components/landing/Contact";
import NewsLetter from "../components/landing/NewsLetter";
import Promo from "../components/landing/Promo";

function LandigPage() {
  return (
    <div>
      <About />
      <NewsLetter />
      <Promo />
      <Awards />
      <Contact />
    </div>
  );
}

export default LandigPage;
